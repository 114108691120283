<template>
    <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop
        :title="trans('as-assignment-information', 288)">
        <b-tabs ref="tabs" class="mb-3" :nav-class="!cArgs.id_person_data_main ? 'd-none' : ''" justified>
            <b-tab v-if="cArgs.id_person_data_main" :title="trans('flexworker', 280)" :active="activeTab == 0"
                @click="refreshTab(0)">
                <b-card v-if="loading">
                    <div class="d-flex justify-content-center">
                        <app-refresh :loading="loading" :refresh="refresh"></app-refresh>
                    </div>
                </b-card>

                <b-card v-if="!loading">

                    <document-file-preview :itemData.sync="preview.item_data" v-if="preview.show"
                        :show.sync="preview.show" />

                    <b-row>
                        <b-col class="mx-3">
                            <b-row class="mb-2 d-flex flex-column app-labels-local" v-for="(item, index) in info_data"
                                :key="index">
                                <div>
                                    <b-row class="mb-1 d-flex align-items-start app-labels-local">
                                        <b-col cols="5">
                                            <b>{{ item.label }}</b>
                                        </b-col>
                                        <b-col cols="7">
                                            <div v-if="!item.custom">
                                                {{ data[item.value] ? data[item.value] : '-' }}
                                            </div>
                                            <div v-else>
                                                <b-row v-if="item.value == 'phone'">
                                                    <b-col v-if="data.phone || data.phone_number">
                                                        {{ data.phone_prefix }} {{ data.phone_number }}
                                                    </b-col>
                                                    <b-col v-else>
                                                        -
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-row>
                        </b-col>
                        <b-col class="mx-3">
                            <b-row class="mb-2 d-flex flex-column app-labels-local" v-if="userPlacement == 1">
                                <div>
                                    <b>Documenten</b>
                                </div>
                                <div
                                    v-if="data.ids_document_data_main && JSON.parse(data.ids_document_data_main)[0].id != null">
                                    <div v-for="(doc, i) in JSON.parse(data.ids_document_data_main)" :key="i"
                                        class="m-1 p-0 download-document-local d-flex align-items-center">
                                        <div @click="downloadFile(doc)">
                                            <b-icon icon="file-earmark" aria-hidden="true"
                                                class="m-1 icon-size"></b-icon>
                                            <span class="ml-1">
                                                {{ doc.document_name ? doc.document_name : doc.file_name_user }}{{ [10, 3].includes(doc.id_document_const_type) ? ` ${doc.entry_date}` : '' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
            <b-tab :title="trans('detachment', 280)" @click="refreshTab(1)" :active="activeTab == 1">
                <b-card v-if="asLoading">
                    <div class="d-flex justify-content-center">
                        <app-refresh :loading="asLoading" :refresh="refresh"></app-refresh>
                    </div>
                </b-card>

                <b-card v-if="!asLoading">
                    <b-row>
                        <b-col class="mx-3">
                            <b-row class="mb-2 d-flex flex-column app-labels-local"
                                v-for="(item, index) in assignment_info_data" :key="index">
                                <div>
                                    <b-row class="mb-1 d-flex align-items-start app-labels-local">
                                        <b-col cols="5">
                                            <b>{{ item.label }}</b>
                                        </b-col>
                                        <b-col cols="7">
                                            <div v-if="!item.custom">
                                                {{ assignment_data[item.value] ? assignment_data[item.value] : '-' }}
                                            </div>
                                            <div v-else>
                                                <b-row v-if="item.value == 'status'">
                                                    <b-col class="d-flex align-items-center">
                                                        {{ assignment_data.status ? assignment_data.status : '-' }}

                                                        <div
                                                            class="d-flex align-items-center justify-content-between ml-2">
                                                            <b-icon icon="info-circle-fill"
                                                                :id="'popover-target-description-status'"
                                                                class="app-cursor-pointer ml-auto mr-auto"
                                                                style="min-width: 17px; min-height: 17px;" />
                                                        </div>
                                                        <b-popover
                                                            v-if="assignment_data.id_assignment_const_status != null"
                                                            :target="'popover-target-description-status'"
                                                            triggers="hover" no-fade placement="bottomright"
                                                            custom-class="w-25 text-justify custom-popover-local-notes">
                                                            {{ popoverContent }}
                                                        </b-popover>
                                                    </b-col>
                                                </b-row>

                                                <b-row v-if="item.value == 'hours_per_week'">
                                                    <b-col class="d-flex align-items-center">
                                                        {{ assignment_data.hours_per_week ?
                                                            assignment_data.hours_per_week : '-' }}

                                                        <div
                                                            class="d-flex align-items-center justify-content-between ml-2">
                                                            <b-icon icon="info-circle-fill"
                                                                :id="'popover-target-description-hours'"
                                                                class="app-cursor-pointer ml-auto mr-auto"
                                                                style="min-width: 17px; min-height: 17px;" />
                                                        </div>
                                                        <b-popover
                                                            v-if="assignment_data.id_assignment_const_status != null"
                                                            :target="'popover-target-description-hours'"
                                                            triggers="hover" no-fade placement="bottomright"
                                                            custom-class="w-25 text-justify custom-popover-local-notes">
                                                            Min. uren per week - Max. uren per week
                                                        </b-popover>
                                                    </b-col>
                                                </b-row>

                                                <b-row v-if="item.value == 'id_employment_data_main'">
                                                    <b-col v-if="assignment_data.id_employment_data_main == 1">
                                                        {{ trans('planning-temporary-worker-and-self-employed', 288) }}
                                                    </b-col>
                                                    <b-col v-else-if="assignment_data.id_employment_data_main == 2">
                                                        {{ trans('planning-temporary-worker', 288) }}
                                                    </b-col>
                                                    <b-col v-else-if="assignment_data.id_employment_data_main == 3">
                                                        {{ trans('zzp', 283) }}
                                                    </b-col>
                                                    <b-col v-else-if="assignment_data.id_employment_data_main == null">
                                                        -
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-row>
                        </b-col>
                        <b-col class="mx-3">
                            <b-row class="mb-2 d-flex flex-column app-labels-local" v-if="userPlacement == 1">
                                <div>
                                    <b>Bijlages</b>
                                </div>
                                <div
                                    v-if="assignment_data.ids_document_data_main && JSON.parse(assignment_data.ids_document_data_main)[0].id != null">
                                    <div v-for="(doc, i) in JSON.parse(assignment_data.ids_document_data_main)" :key="i"
                                        class="m-1 download-document-local d-flex align-items-center">
                                        <div @click="downloadFile(doc, i)">
                                            <b-icon v-if="downloadingFile != i" icon="file-earmark" aria-hidden="true"
                                                class="m-1 icon-size"></b-icon>
                                            <b-spinner v-else
                                                style="width:16px; height:16px; margin-left:4px; margin-right: 4px"
                                                small></b-spinner>
                                            <span class="ml-1">
                                                {{ doc.file_name_user ? doc.file_name_user : doc.file_name_storage }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
        </b-tabs>

        <offers-assignments-shifts v-if="[4, 5].includes(cArgs.id_assignment_const_status)" class="mt-3"
            :id_person_data_main="data.id_person_data_main"
            :id_assignment_data_main="cArgs.id_assignment_data_main"></offers-assignments-shifts>

        <template #modal-footer>
            <app-button v-if="cArgs.id_assignment_const_status == 2" type="secondary"
                @click="rejectPerson">Afwijzen</app-button><br>
            <app-button v-if="cArgs.id_assignment_const_status == 2" type="primary"
                @click="confirmPersonByClient">Bevestigen</app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import AppAlerts from '@core/scripts/AppAlerts';
import DocumentFilePreview from '../Shifts/DocumentFilePreview.vue';
import OffersAssignmentsShifts from "./Components/OffersAssignmentsShifts.vue";

export default {

    components: {
        OffersAssignmentsShifts,
        DocumentFilePreview
    },

    props: {
        show: {
            type: Boolean,
            required: true
        },
        args: {
            type: Object,
            required: true
        },
        result: {
            type: Number,
            required: true
        },
        return: {
            type: Object,
            required: true
        },
        getList: {
            type: Function,
            required: true
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) },
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        popoverContent: function () {
            const status = this.assignment_data.id_assignment_const_status;

            switch (status) {
                case 1:
                    return 'De opdracht is aangemaakt en we zijn op zoek naar een match.';
                case 8:
                    return 'We hebben een mogelijke match en stellen zo snel mogelijk iemand voor.';
                case 2:
                    return 'We hebben mensen voorgesteld om de opdracht in te vullen.';
                case 3:
                    return 'Er wordt zo snel mogelijk een afspraak gemaakt voor een kennismaking.';
                case 4:
                    return 'De opdracht is bevestigd.';
                case 5:
                    return 'De opdracht is voltooid.';
                default:
                    return '-';
            }
        }
    },

    watch: {

    },

    created() {
        this.getFWInfo();
        this.getAssignmentInfo();
    },

    data() {
        return {
            userPlacement: JSON.parse(localStorage.getItem('user-portal')).placement,
            alert_class: new AppAlerts(),
            loading: false,
            asLoading: false,
            refresh: 0,
            data: {},
            assignment_data: {},
            info_data: [
                { label: "Naam", value: "person_name" },
                { label: "E-mail", value: "email" },
                { label: "Telefoonnummer", value: "phone", custom: true },
                { label: "Geboortedatum", value: "birth_date" },
                { label: "Functies", value: "function_names" },
                { label: "Kwalificaties", value: "qualification_names" },
            ],
            assignment_info_data: [
                { label: "Dienstverband", value: "id_employment_data_main", custom: true },
                { label: "Contactpersoon", value: "contact_person_name" },
                { label: "Soort dienst", value: "hours" },
                { label: "Beschikbare uren", value: "hours_per_week", custom: true },
                { label: "Status", value: "status", custom: true },
                { label: "Opdrachtbeschrijving", value: "description" },
            ],
            activeTab: 0,
            preview: {
                show: false,
                item_data: {
                    base_64: null,
                    url: null,
                    type: null,
                    title: null
                }
            },
            downloadingFile: null
        }
    },

    methods: {
        getFWInfo() {
            if (!this.cArgs.id_person_data_main) return;
            this.loading = true;
            axios.post("assignments/getFWInfo", {
                id_person_data_main: this.cArgs.id_person_data_main
            }).then(response => {
                this.data = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.refresh++;
            });
        },

        getAssignmentInfo() {
            this.asLoading = true;
            axios.post("assignments/getAssignmentInfo", {
                id_assignment_data_main: this.cArgs.id_assignment_data_main
            }).then(response => {
                this.assignment_data = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.asLoading = false;
                this.refresh++;
            });
        },

        downloadFile(item, index) {
            if (this.downloadingFile == null) {
                this.downloadingFile = index;
                axios.post("assignments/downloadFile", {
                    id_document_data_main: item.id,
                    id_person_data_main: this.data.id_person_data_main,
                    id_assignment_data_main: this.cArgs.id_assignment_data_main
                }).then(response => {
                    const validTypes = ['pdf', 'png', 'jpeg', 'jpg'];
                    if (response.data.type == 'docx' || response.data.type == 'doc') {
                        this.preview.item_data.url = response.data.url;
                        this.preview.item_data.type = response.data.type;
                        this.preview.item_data.title = item.document_name;
                        this.preview.show = true;
                    }
                    else if (validTypes.includes(response.data.type.toLowerCase())) {
                        this.preview.item_data.base_64 = response.data.base64;
                        this.preview.item_data.type = response.data.type;
                        this.preview.item_data.title = item.document_name;
                        this.preview.show = true;
                    } else {
                        this.alert_class.openAlertWarning(this.trans('file-not-found', 281)).then(res => { });
                    }
                    this.downloadingFile = null;
                }).catch(error => {
                    console.error(`error during request: ${error}`);
                    this.downloadingFile = null;
                    this.alert_class.openAlertWarning(this.trans('file-not-found', 281)).then(res => { })
                });
            }
        },

        confirmPersonByClient() {
            const message = this.trans('as-person-confirmation', 291, { first_name: this.data.first_name, last_name: this.data.last_name });

            this.alert_class.openAlertConfirmation(message).then(res => {
                if (res == true) {
                    this.loading = true;

                    axios
                        .post('assignments/confirmByClient', {
                            id_assignment_data_main: this.cArgs.id_assignment_data_main,
                            id_assignment_ref_applicants: this.cArgs.id_assignment_ref_applicants,
                            id_person_data_main: this.cArgs.id_person_data_main,
                        })
                        .then((res) => {
                            this.getList();
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        });
                }
            });
        },

        rejectPerson() {
            const message = this.trans('as-person-rejection', 291, { first_name: this.data.first_name, last_name: this.data.last_name });

            this.alert_class.openAlertConfirmation(message).then(res => {
                if (res == true) {
                    this.loading = true;

                    axios
                        .post('assignments/reject', {
                            id_assignment_data_main: this.cArgs.id_assignment_data_main,
                            id_assignment_ref_applicants: this.cArgs.id_assignment_ref_applicants,
                        })
                        .then((res) => {
                            this.getList();
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        })
                }
            });
        },

        refreshTab(e) {
            if (this.activeTab !== e) {
                this.activeTab = e;
                // e === 0 ? this.getFWInfo() : this.getAssignmentInfo();
            }
        },

        closeModal() {
            this.computedShow = false;
        }
    }

}
</script>
<style scoped>
.app-labels-local {
    font-size: 12.8px;
    flex-wrap: nowrap !important;
}

.icon-size {
    font-size: 1rem;
    line-height: 1;
}

.download-document-local {
    min-height: 1.5rem;
}

.download-document-local:hover {
    cursor: pointer;
    text-decoration: underline;
}
</style>