<template>
    <b-row class="mt-3 pt-3">
        <b-col cols="12">
            <b-card>
                <b-overlay :show="loading_list" :opacity="0.6">
                    <b-row class="app-card-header app-local-header-height">
                        <b-col start>
                            <div class="app-card-tittle app-local-title-height d-flex align-items-center">
                                <div>{{trans('assignments', 280)}} <span v-if="!loading_list">({{
                                    items.total
                                        }})</span></div>
                            </div>
                        </b-col>

                        <b-col cols="auto" class="app-local-big pl-0" end>
                            <b-form-input type="text" v-model="filters.search" :lazy="true"
                                class="form-control app-input"
                                :class="{ 'app-input-filled': filters.search.length > 0 }"
                                :placeholder="trans('search', 280)" size="sm" />
                        </b-col>

                        <b-col cols="auto" class="pl-0 app-local-big" end>
                            <app-date-week-selector :choosedDate.sync="filters.assignment_date"
                                :listType.sync="filters.date_type" :changeArrows="true"
                                :weekDates.sync="filters.assignment_week" :key="refresh" :clearable="true"
                                :onlyWeek="false" />
                        </b-col>

                        <b-col cols="auto" end class="pl-0 app-local-big">
                            <b-button-group size="sm">
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 1, 'app-list-button-selected': filters.type === 1 }"
                                    @click="filters.type = 1">{{ trans('open', 282) }}</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 2, 'app-list-button-selected': filters.type === 2 }"
                                    @click="filters.type = 2">{{ trans('sh-in-progress', 292) }}</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 3, 'app-list-button-selected': filters.type === 3 }"
                                    @click="filters.type = 3">{{ trans('sh-accept', 292) }}</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 7, 'app-list-button-selected': filters.type === 7 }"
                                    @click="filters.type = 7">Kennismaking</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 4, 'app-list-button-selected': filters.type === 4 }"
                                    @click="filters.type = 4">{{ trans('sh-confirmed', 294) }}</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 5, 'app-list-button-selected': filters.type === 5 }"
                                    @click="filters.type = 5">{{ trans('sh-completed', 292) }}</b-btn>
                                <b-btn class="app-input-min-height app-list-button"
                                    v-bind:class="{ 'btn-secondary-grey': filters.type !== 6, 'app-list-button-selected': filters.type === 6 }"
                                    @click="filters.type = 6">{{ trans('sh-all', 292) }}</b-btn>
                            </b-button-group>
                        </b-col>

                        <b-col cols="auto" class="pl-0 d-flex align-items-center">
                            <div>
                                <app-refresh :refresh.sync="refresh" :loading.sync="loading_list" />
                            </div>
                        </b-col>
                    </b-row>

                    <app-view-table :isTopBar="false" :filters="filters" :items="items" :fields="fields"
                        :customColumns="custom_columns" :customColumnsHeaders="custom_headers" :isDropdownMenu="false"
                        menuCondition="item.id_person_data_main != null" v-on:onRowClicked="handleRowClicked" resizeableColumns resizeMinWidth :key="refreshTable">

                        <template v-slot:head_selected>
                            <app-check-box class="pb-2 pl-2" v-model="selected_all_rows" @change="selectAll" />
                        </template>

                        <template v-slot:selected="cell">
                            <app-check-box class="pl-2" v-model="cell.data.item.selected" @change="select($event)" />
                        </template>

                        <template v-slot:person_qualification_compatibility="cell">
                            <offers-shifts-qualifications-relations
                                :dotColor="cell.data.item.ids_qualification_const_type && JSON.parse(cell.data.item.ids_qualification_const_type).length != 0 ? cell.data.item.person_qualification_compatibility : 2"
                                :idPersonDataMain="cell.data.item.id_person_data_main"
                                :idsShiftDataMain="[cell.data.item.id_assignment_data_main]"
                                :ids_qualification_const_type="JSON.parse(cell.data.item.ids_qualification_const_type)"
                                :queryType="2" />
                        </template>

                        <template v-slot:person_informations="cell">
                            {{ cell.data.item.person_name }} <br />
                            {{ cell.data.item.applicant_function_name }} <b-icon icon="info-circle-fill"
                                :id="'popover-target-applicant_function' + cell.data.item.id_hour_data_main"
                                v-if="cell.data.item.applicant_function_id != null && cell.data.item.applicant_function_id != cell.data.item.person_id_function_data_main"
                                class="ml-auto mb-1" style="min-width: 17px; min-height: 17px;" />

                            <b-popover :target="'popover-target-applicant_function' + cell.data.item.id_hour_data_main"
                                triggers="hover" no-fade placement="bottomright"
                                v-if="cell.data.item.applicant_function_id != null && cell.data.item.applicant_function_id != cell.data.item.person_id_function_data_main">
                                <template #title>
                                    Geregistreerde functie
                                </template>
                                De hoofdfunctie van de persoon verschilt van de functie die voor de dienst is
                                geregistreerd: <b>{{
                                    cell.data.item.person_function_name ? cell.data.item.person_function_name :
                                        '-' }}</b>
                            </b-popover>
                        </template>

                        <template v-slot:department="cell">
                            {{ cell.data.item.location_name }} <br />
                            {{ cell.data.item.department_name }}
                        </template>

                        <template v-slot:menu-items="cell">
                            <b-dropdown-item v-if="cell.data.item.id_person_data_main != null"
                                @click="openFWInfo(cell.data.item)">Flexwerker info</b-dropdown-item>
                        </template>

                        <template v-slot:buttons>

                        </template>

                    </app-view-table>

                    <template #overlay>
                        <div></div>
                    </template>
                </b-overlay>
            </b-card>

            <offers-assignments-person-info v-if="mFlexwerker.show" :show.sync="mFlexwerker.show"
                :args.sync="mFlexwerker.args" :result.sync="mFlexwerker.result" :return.sync="mFlexwerker.return"
                :getList="getList" />
        </b-col>
    </b-row>
</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";
import OffersShiftsQualificationsRelations from "../Shifts/PersonAdd/Components/OffersShiftsQualificationsRelations.vue";
import OffersAssignmentsPersonInfo from "./OffersAssignmentsPersonInfo.vue";

export default {
    components: {
        OffersShiftsQualificationsRelations,
        OffersAssignmentsPersonInfo
    },

    created() {
        this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
        this.userHours = JSON.parse(localStorage.getItem('user-portal')).hours
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            refreshTable: 0,
            multi_menu_confirm_reject_by_client: false,
            filters: {
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                date_type: 3,
                assignment_date: moment().format('DD-MM-YYYY'),
                assignment_week: this.getCorrectWeek(),
                ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
                ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),
                client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,

                type: 6,
                filtering: {
                    locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass: 'app-width-min-250' },
                    departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass: 'app-width-min-250' },
                    functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass: 'app-width-min-350' },
                    dates_from: { type: 'dates', changed: 0, values: [], function: "", parameters: { type: null }, widthClass: 'app-width-min-350' },
                    dates_to: { type: 'dates', changed: 0, values: [], function: "", parameters: { type: null }, widthClass: 'app-width-min-350' },
                    persons: { type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: null, widthClass: 'app-width-min-250' },
                },
                sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0
                }
            },

            fields: [
                { key: "selected", label: "", tdClass: "p-0", thClass: "p-0", thStyle: { "min-width": "30px", width: "30px" }, visible: true },
                { key: "location_name", label: 'Locatie', thStyle: { "min-width": "265px", "width": "265px" }, visible: true, filtering: true, filtering: true, filtering_name: "locations" },
                { key: "department_names", label: this.trans('h-department', 305), thStyle: { "min-width": "500px", "width": "500px" }, visible: true, filtering: true, filtering: true, filtering_name: "departments" },
                { key: "person_qualification_compatibility", label: 'KW', thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false, sortable: false },
                { key: "function_name", label: this.trans('sh-function', 292), thStyle: { "min-width": "220px", "max-width": "220px" }, visible: true, filtering: true, filtering_name: "functions" },
                { key: "date_from", label: this.trans('date-start', 283), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "dates_from" },
                { key: "date_to", label: this.trans('date-end', 283), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "dates_to" },
                { key: "person_name", label: this.trans('flexworker', 280), thStyle: { "min-width": "165px", "width": "100%" }, visible: true, filtering: true, filtering_name: "persons" },
                { key: "status_name", label: this.trans('status', 283), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: false, filtering: false, filtering_name: "status", sortable: false },
            ],

            custom_columns: ['selected', 'person_qualification_compatibility'],
            custom_headers: ['selected'],
            selected_all_rows: '0',
            checked_items: 0,
            removeAll: false,
            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            loading_list: false,
            loading_button: false,
            refresh: 0,
            sidebar_open: false,
            multiMenuVisible: true,
            confirmVisible: true,
            rejectVisible: true,
            cancelVisible: true,
            mCancelReason: {
                show: false,
                args: {
                    client_description: '',
                    ids_hour_data_main: [],
                    person_name: null,
                    id_cancel_const_reason: null
                }, result: 0, return: null
            },
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main).map(item => item.value),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main).map(item => item.value),

            mFlexwerker: {
                show: false,
                args: {
                    id_person_data_main: null,
                    id_assignment_data_main: null
                }, result: 0, return: {}
            }
        };
    },

    methods: {

        getCorrectWeek() {

            const date = moment();

            const isoYear = date.isoWeekYear();
            const isoWeek = date.isoWeek();

            return `${isoYear}-${String(isoWeek)}`;
        },

        getList() {
            this.loading_list = true;
            axios
                .post("assignments/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                });
        },

        select(value) {

            var selected_items = 0;

            selected_items = this.items.list.filter((item) => { return item.selected == '1' }).length;

            this.checked_items = selected_items
        },

        selectAll(value) {
            if (value == "1") {
                this.items.list.forEach(item => {
                    item.selected = "1";
                });

                this.checked_items = this.items.list.length;
            } else {
                this.items.list.forEach(item => {
                    item.selected = "0";
                });

                this.checked_items = 0;
            }
        },

        saveSettings() {
            // this.userSettings.saveSettings(this.filters, 27);
        },

        async loadSettings() {
            // var sFilters = await this.userSettings.checkSettings(27, this.filters, 'portalAssignmenstList');

            // this.filters.ids_location_data_main = this.ids_location_data_main;
            // this.filters.ids_department_data_main = this.ids_department_data_main;
            // this.filters.client_link = this.client_link;

            // this.filters.filtering = sFilters.filtering;
            // this.filters.sorting = sFilters.sorting;
            // this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;
            // this.filters.type = sFilters.type;
            // this.filters.date_type = sFilters.date_type;
            // this.filters.assignment_date = sFilters.assignment_date == null ? moment().format('DD-MM-YYYY') : sFilters.assignment_date;
            // this.filters.assignment_week = sFilters.assignment_week == null ? moment().format('YYYY-W') : sFilters.assignment_week;

            if (this.$route.params.type && this.$route.params.type == 1) {
                this.filters.type = 3;
                this.filters.date_type = 3;
            } else if (this.$route.params.type && this.$route.params.type == 2) {
                this.filters.type = 6;
                this.filters.date_type = 3;
            }

            this.filters.type === 6 ? this.fields.find(item => item.key === "status_name").visible = true : this.fields.find(item => item.key === "status_name").visible = false;
            this.refreshTable++;
            await this.$nextTick();
        },

        openFWInfo(item) {
            this.mFlexwerker.args.id_person_data_main = item.id_person_data_main;
            this.mFlexwerker.args.id_assignment_data_main = item.id_assignment_data_main;
            this.mFlexwerker.args.id_assignment_const_status = item.id_assignment_const_status;
            this.mFlexwerker.args.id_assignment_ref_applicants = item.id_assignment_ref_applicants;

            this.mFlexwerker.args.filters = this.filters;

            this.mFlexwerker.show = true;
        },

        handleRowClicked(item) {
            this.openFWInfo(item);
        },

        async onCreateThis() {

            await this.loadSettings();
            this.getList();

            this.$watch('filters.assignment_date', function (newVal, oldVal) {
                if (!this.loading_list && this.filters.date_type == 1) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.type', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.type === 1 ? this.fields.find(item => item.key === "person_name").visible = false : this.fields.find(item => item.key === "person_name").visible = true;
                    this.filters.type === 6 ? this.fields.find(item => item.key === "status_name").visible = true : this.fields.find(item => item.key === "status_name").visible = false;
                    this.refreshTable++;
                    this.items = [];
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.assignment_week', function (newVal, oldVal) {
                if (!this.loading_list && this.filters.date_type == 2) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });


            this.$watch('filters.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                }
            });

            this.$watch('filters.date_type', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });


            this.$watch('refresh', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                }
            });

            this.$watch('filters.page', function (newVal, oldVal) {
                this.cheked_items = 0;
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                }
            });

            this.$watch('filters.search', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                }
            });

            this.$watch('filters.sorting.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.locations.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.departments.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.functions.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.dates_from.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.dates_to.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('filters.filtering.persons.changed', function (newVal, oldVal) {
                if (!this.loading_list) {
                    this.checked_items = 0;
                    this.selected_all_rows = '0';
                    this.getList();
                    this.saveSettings();
                }
            });

            this.$watch('mCancelReason.result', function (newVal, oldVal) {
                if (this.mCancelReason.result == 1) {
                    this.reject();

                    this.mCancelReason.result = 0;
                }
            });

        },
    },
};
</script>

<style scoped>
.app-local-fw-buttons {
    max-width: 230px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.app-local-daytime {}
</style>
